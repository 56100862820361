import React from 'react';

const Japanese = () => {
    return (
        <div className='mt-5 pt-5'>
            <h1 className='text-primary'>The Japanese Website will be constructed soon.</h1>
        </div>
    );
};

export default Japanese;