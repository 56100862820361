import React from 'react';
import DonorBanner from '../DonorBanner/DonorBanner';
import Navigation from '../Shared/Navigation/Navigation';

const DonorForm = () => {
    return (
        <div>
          <Navigation></Navigation>
          <DonorBanner></DonorBanner>
         

        </div>
    );
};

export default DonorForm;